import * as React from 'react';
import SingleColumnLayout from '../layouts/singleColumnLayout/SingleColumnLayout';
import SEO from '../components/SEO/SEO';

/**
 * @author ESTECO CSA Team <csa@esteco.com>
 */
const RegisterPage: React.FC = () => (
    <SingleColumnLayout>
        <SEO description='Register for ESTECO UM20 and join the VOLTA and modeFRONTIER users conference remotely or on-site.
        Both options are free, just choose your ticket.'
             title='ESTECO UM20 | Register now and join the event remotely or in person.'/>
        <div className="centered-column">
            <div className="soul-space-stack-bottom-xl"/>
            <p className="soul-space-inset-m register-now__title">Register FREE</p>
            <iframe
                className="register-now__embedded-form"
                src="https://www.eventbrite.it/checkout-external?eid=91306192279&amp;parent=https%3A%2F%2Fwww.esteco.com%2Fcorporate%2Festeco-users-meeting-2020"
                data-automation="checkout-widget-iframe-91306192279"
                frameBorder="0"
                scrolling="auto"
                width="100%"
                height="100%"/>
        </div>
    </SingleColumnLayout>
);

export default RegisterPage;
